export const normalizePrice = (msrp: number, price: number) => {
    if (price < 0 && msrp < 0) return 0;
    if (price < 0) return msrp;
    return price;
};

const numberFormat = new Intl.NumberFormat('en-us', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});
const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});
export const formatNumber = (value: number) => numberFormat.format(value);
export const formatPrice = (value: number) => currencyFormat.format(value);

export const rawMonthlyPayment = (total: number, apr: number, term: number) => {
    // No term means no monthly payment, so return total
    if (term <= 0) {
        return total;
    }

    // Using extremely low apr values results in Infinity.
    else if (apr <= 0.00001) {
        // No apr means no interest, so just divide total by term
        return total / term;
    }

    // Monthly payment formula
    else {
        const mInterest = apr / 12;
        const mPayment =
            total * (mInterest / (1 - Math.pow(1 + mInterest, -term)));

        return Math.round(mPayment);
    }
};

export const calculateFeeByRate = (price: number, rate: number) => {
    if (price >= 0) {
        return Math.floor(price * rate);
    } else {
        return 0;
    }
};
